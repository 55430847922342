import moment from "moment";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";


export const BillingInvoice = async (data: any, billingInfo: any) => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([600, 800]);

  let isBillingInfoAvailable = true;

  const primaryData = billingInfo.find((item: any) => item.isDefault === true);

  if (!primaryData) {
    isBillingInfoAvailable = false;
  }

  const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  page.setFont(regularFont);
  page.setFontSize(12);

  page.drawLine({
    start: { x: 20, y: 780 },
    end: { x: 580, y: 780 },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
  });
  page.drawLine({
    start: { x: 20, y: 780 },
    end: { x: 20, y: 20 },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
  });
  page.drawLine({
    start: { x: 580, y: 780 },
    end: { x: 580, y: 20 },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
  });
  page.drawLine({
    start: { x: 20, y: 20 },
    end: { x: 580, y: 20 },
    thickness: 1,
    color: rgb(0.5, 0.5, 0.5),
  });

  page.setFont(boldFont);
  page.drawText("INVOICE", { x: 50, y: 750, size: 14, color: rgb(0, 0, 0) });

  page.setFont(regularFont);

  page.drawLine({
    start: { x: 50, y: 735 },
    end: { x: 550, y: 735 },
    thickness: 1.5,
    color: rgb(0.5, 0.5, 0.5),
  });

  page.drawText(`Invoice Id: `, {
    x: 50,
    y: 720,
    size: 9,
    color: rgb(0.392, 0.459, 0.584),
  });
  page.setFont(boldFont);

  page.drawText(`${data?.sequence_id}`, {
    x: 170,
    y: 720,
    size: 9,
  });
  page.setFont(regularFont);

  page.drawText(`Issue Date:`, {
    x: 50,
    y: 705,
    size: 9,
    color: rgb(0.392, 0.459, 0.584),
  });
  page.setFont(boldFont);

  page.drawText(`${moment(data?.created_at).format("MMM DD, YYYY")}`, {
    x: 170,
    y: 705,
    size: 9,
  });
  page.setFont(regularFont);

  page.drawText(`Due Date: `, {
    x: 50,
    y: 690,
    size: 9,
    color: rgb(0.392, 0.459, 0.584),
  });
  page.setFont(boldFont);

  page.drawText(`${moment(data?.due_date).format("MMM DD, YYYY")}`, {
    x: 170,
    y: 690,
    size: 9,
  });
  page.setFont(regularFont);

  page.drawText(`Invoice Period:`, {
    x: 50,
    y: 675,
    size: 9,
    color: rgb(0.392, 0.459, 0.584),
  });
  page.setFont(boldFont);

  page.drawText(
    `${moment(data?.start_date).format("MMM DD, YYYY")} - ${moment(
      data?.end_date
    ).format("MMM DD, YYYY")}`,
    {
      x: 170,
      y: 675,
      size: 9,
    }
  );
  page.setFont(regularFont);

  page.drawLine({
    start: { x: 50, y: 660 },
    end: { x: 550, y: 660 },
    thickness: 1.5,
    color: rgb(0.5, 0.5, 0.5),
  });

  if (isBillingInfoAvailable) {
    page.drawText("Billed By", {
      x: 50,
      y: 640,
      size: 8,
      color: rgb(0, 0, 0),
    });
    page.drawText(primaryData?.name, {
      x: 50,
      y: 625,
      size: 8,
      color: rgb(0.5, 0.5, 0.5),
    });
    page.drawText(
      `${primaryData?.address?.line1 || ""}${
        primaryData?.address?.line2 ? `, ${primaryData.address.line2}` : ""
      }${primaryData?.address?.city ? `, ${primaryData.address.city}` : ""}${
        primaryData?.address?.state ? `, ${primaryData.address.state}` : ""
      }${
        primaryData?.address?.country ? `, ${primaryData.address.country}` : ""
      }`,
      {
        x: 50,
        y: 610,
        maxWidth: 200,
        size: 8,
        color: rgb(0.5, 0.5, 0.5),
      }
    );
    page.drawText(primaryData?.email, {
      x: 50,
      y: 595,
      size: 8,
      color: rgb(0.5, 0.5, 0.5),
    });
  }

  page.drawText("Billed To", {
    x: isBillingInfoAvailable ? 300 : 50,
    y: 640,
    size: 8,
    color: rgb(0, 0, 0),
  });
  page.drawText(data?.customer_account_name, {
    x: isBillingInfoAvailable ? 300 : 50,
    y: 625,
    size: 8,
    color: rgb(0.392, 0.459, 0.584),
  });
  page.drawText(
    `${data?.customer?.line1 || ""}${
      data?.customer?.line2 ? `, ${data.customer.line2}` : ""
    }${data?.customer?.city ? `, ${data.customer.city}` : ""}${
      data?.customer?.state ? `, ${data.customer.state}` : ""
    }${data?.customer?.country ? `, ${data.customer.country}` : ""}`,
    {
      x: isBillingInfoAvailable ? 300 : 50,
      y: 610,
      maxWidth: 200,
      size: 8,
      color: rgb(0.392, 0.459, 0.584),
    }
  );
  page.drawText(data?.customer?.email, {
    x: isBillingInfoAvailable ? 300 : 50,
    y: 595,
    size: 8,
    color: rgb(0.392, 0.459, 0.584),
  });

  page.drawLine({
    start: { x: 50, y: 580 },
    end: { x: 550, y: 580 },
    thickness: 1.5,
    color: rgb(0.5, 0.5, 0.5),
  });
  page.setFont(boldFont);

  page.drawText(`Total: ${data?.total_amount} USD`, {
    x: 50,
    y: 560,
    size: 12,
    color: rgb(0, 0, 0),
  });
  page.setFont(regularFont);

  page.drawLine({
    start: { x: 50, y: 540 },
    end: { x: 550, y: 540 },
    thickness: 1.5,
    color: rgb(0.5, 0.5, 0.5),
  });
  page.drawLine({
    start: { x: 50, y: 515 },
    end: { x: 550, y: 515 },
    thickness: 0.1,
    color: rgb(0.5, 0.5, 0.5),
  });

  page.setFont(boldFont);

  page.drawText("Item Name", {
    x: 50,
    y: 500,
    size: 9,
    color: rgb(0, 0, 0),
  });
  page.drawText("Quantity", {
    x: 200,
    y: 500,
    size: 9,
    color: rgb(0, 0, 0),
  });
  page.drawText("Price", { x: 300, y: 500, size: 9, color: rgb(0, 0, 0) });
  page.drawText("Total", { x: 400, y: 500, size: 9, color: rgb(0, 0, 0) });

  page.drawLine({
    start: { x: 50, y: 490 },
    end: { x: 550, y: 490 },
    thickness: 0.1,
    color: rgb(0.5, 0.5, 0.5),
  });

  let currentYPosition = 470;
  let currencyCode = data?.currency_code;
  data.items?.forEach((item: any) => {
    page.setFont(regularFont);
    page.drawText(item?.item_title, {
      x: 50,
      y: currentYPosition,
      size: 9,
    });
    page.drawText(item?.quantity ? String(item?.quantity) : "0", {
      x: 230,
      y: currentYPosition,
      size: 9,
    });
    page.drawText(
      item?.unit_price ? `${item?.unit_price} ${currencyCode}` : "",
      {
        x: 300,
        y: currentYPosition,
        size: 9,
      }
    );
    page.drawText(
      item?.total_value
        ? `${item?.total_value} ${currencyCode}`
        : `0.00  ${currencyCode}`,
      {
        x: 400,
        y: currentYPosition,
        size: 9,
      }
    );

    currentYPosition -= 20;

    if (
      item?.item_type === "USAGE BASED FEE" &&
      Array.isArray(item?.metadata) &&
      item.metadata.length > 0
    ) {
      item.metadata.forEach((metadataItem: any) => {
        page.setFont(regularFont);
        page.drawText(
          `${metadataItem?.name} ${
            metadataItem?.range?.split(" - ")[0]
          } [${metadataItem?.type?.toUpperCase()}]`,
          {
            x: 60,
            y: currentYPosition,
            size: 9,
            color: rgb(0.392, 0.459, 0.584),
          }
        );
        page.drawText(
          metadataItem?.quantity ? String(metadataItem?.quantity) : "0",
          {
            x: 230,
            y: currentYPosition,
            size: 9,
            color: rgb(0.392, 0.459, 0.584),
          }
        );
        page.drawText(
          metadataItem?.amount?.value
            ? `${metadataItem?.amount?.value} ${metadataItem?.amount?.currency}`
            : "",
          {
            x: 300,
            y: currentYPosition,
            size: 9,
            color: rgb(0.392, 0.459, 0.584),
          }
        );
        page.drawText(
          metadataItem?.total?.value
            ? `${metadataItem?.total?.value} ${metadataItem?.total?.currency}`
            : `0 ${metadataItem?.total?.currency}`,
          {
            x: 400,
            y: currentYPosition,
            size: 9,
            color: rgb(0.392, 0.459, 0.584),
          }
        );

        currentYPosition -= 20;
      });
    }
  });

  page.drawLine({
    start: { x: 50, y: currentYPosition - 10 },
    end: { x: 550, y: currentYPosition - 10 },
    thickness: 0.1,
    color: rgb(0.5, 0.5, 0.5),
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "invoice.pdf";
  link.click();
};
