import React, { useState, useEffect, useMemo } from "react";
import { Space, Tag } from "antd";
import { CustomDivider } from "../userProfile/style";
import { BillingInfoContainer } from "./Styles";
import { StyledTag } from "../../../components/common/tag/styles";

const BusinessDetails: React.FC<any> = ({ selectedRow }) => {
  const businessDetails = useMemo(() => {
    return selectedRow
      ? [
          { label: "Business Name", value: selectedRow.name || "-" },
          { label: "Primary Email", value: selectedRow.email },
          { label: "Phone Number", value: selectedRow.address.phone || "-" },
          { label: "Line 1", value: selectedRow.address.line1 },
          { label: "Line 2", value: selectedRow.address.line2 || "-" },
          { label: "City", value: selectedRow.address.city || "-" },
          {
            label: "Postal Code",
            value: selectedRow.address.postalCode || "-",
          },
          { label: "State", value: selectedRow.address.state || "-" },
          { label: "Country", value: selectedRow.country },
        ]
      : [];
  }, [selectedRow]);

  const customFields = useMemo(() => {
    return selectedRow?.metadata?.map((data: any) => (
      <div key={data.key} className="billing-info-item">
        <div className="label">{data.key}</div>
        <div className="value">{data.value}</div>
      </div>
    ));
  }, [selectedRow?.metadata]);

  return (
    <BillingInfoContainer>
      {businessDetails.slice(0, 2)?.map(({ label, value }) => (
        <div key={label} className="billing-info-item">
          <div className="label">{label}</div>
          <div className="value">
            {label === "Business Name" && selectedRow.isDefault ? (
              <div style={{ display: "flex", gap: "8px", lineHeight: "24px" }}>
                {value} <StyledTag color="green">PRIMARY</StyledTag>
              </div>
            ) : (
              value
            )}
          </div>
        </div>
      ))}

      <CustomDivider />

      {businessDetails.slice(2)?.map(({ label, value }) => (
        <div key={label} className="billing-info-item">
          <div className="label">{label}</div>
          <div className="value">{value}</div>
        </div>
      ))}

      <CustomDivider />
      {customFields && (
        <div className="billing-info-item">
          <div className="custom-field">Custom Fields:</div>
          {customFields}
        </div>
      )}
    </BillingInfoContainer>
  );
};

export default BusinessDetails;
