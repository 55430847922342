import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logos/billoptim-logo.png";
import { useDispatch, useSelector } from "react-redux";
import ProfileDropdown from "./ProfileDropdown";
import {
  ContactsOutlined,
  DashboardOutlined,
  NodeIndexOutlined,
  SettingOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { AppDispatch } from "../../redux/store";
import { setViewAccountDetails } from "../../redux/feature/customer/customerSlice";
import { setEmptyBillingRateCards } from "../../redux/feature/pricePlan/pricePlanSlice";

const Sidenav: React.FC<{ color: string }> = ({ color }) => {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { filter } = useSelector((state: any) => state.customer);
  const dispatch = useDispatch<AppDispatch>();

  const billing = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const handleMenuClick = () => {
    dispatch(setEmptyBillingRateCards({ rateCards: [], pricingRules: [] }));
    dispatch(setViewAccountDetails(""));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div>
        <div className="brand">
          <img src={logo} alt="" style={{width:"140px",height:'50px'}}/>
        </div>
        <hr />

        <Menu theme="light" mode="inline" defaultOpenKeys={["1"]}>
          <Menu.Item key="1" onClick={handleMenuClick}>
            <NavLink to="/admin-center">
              <span
                className="icon"
                style={{
                  background: page === "admin-center" ? color : "",
                }}
              >
                <SettingOutlined />
              </span>
              <span className="label">Admin Center</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="2" onClick={handleMenuClick}>
            <NavLink to="/price-plan">
              <span
                className="icon"
                style={{
                  background: page === "price-plan" ? color : "",
                }}
              >
                <TagOutlined
                  style={{
                    rotate: "-87deg",
                  }}
                />
              </span>
              <span className="label">Price Plans</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="3" onClick={handleMenuClick}>
            <NavLink
              to="/events"
              className={
                page === "events" || page === "event-schemas" ? "active" : ""
              }
            >
              <span
                className="icon"
                style={{
                  background: page === "events" ? color : "",
                }}
              >
                <NodeIndexOutlined />
              </span>
              <span className="label">Events</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4" onClick={handleMenuClick}>
            <NavLink
              to="/usage-meter"
              className={
                page === "usage-meter" || page === "add-ons" ? "active" : ""
              }
            >
              <span
                className="icon"
                style={{
                  background: page === "usage-meter" ? color : "",
                }}
              >
                <DashboardOutlined />
              </span>
              <span className="label">Billable Items</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="5" onClick={handleMenuClick}>
            <NavLink
              to="/billing/invoice"
              style={({ isActive }) => ({
                background: isActive || page.startsWith('billing') ? "#fff" : "",
                boxShadow:page.startsWith('billing')
                  ? "0 20px 27px rgb(0 0 0 / 5%)"
                  : "unset",
                fontWeight: page.startsWith('billing') ? 600 : "unset",
              })}
            >
              <span
                className="icon"
                style={{
                  background:
                  page.startsWith('billing')  ? color : "",
                }}
              >
                {billing}
              </span>
              <span className="label">Billing</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="6" onClick={handleMenuClick}>
            <NavLink
              to={`/customer?__sort__=${filter}`}
              className={
                ['customer', 'customers', 'accounts'].some((substring) => page.includes(substring)) 
                  ? "active" 
                  : ""
              }
            >
              <span
                className="icon"
                style={{
                  background: page === "customer" || page === "customers" || page === "accounts" ? color : "",
                }}
              >
                <ContactsOutlined />
              </span>
              <span className="label">Customers</span>
            </NavLink>
          </Menu.Item>
        
        </Menu>
      </div>
      <ProfileDropdown />
    </div>
  );
};

export default Sidenav;
