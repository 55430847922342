import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
} from "antd";

import { Link, useNavigate } from "react-router-dom";
import api from "../../api/apiClient";
import { useDispatch } from "react-redux";
import { ArrowRightOutlined } from "@ant-design/icons";
import { OnboardingCard } from "./Styled";
import { emailRegex } from "../../utils/helper";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await api.post("forget-password", values);
      message.success(response?.data?.message);
      navigate("/login");
    } catch (error: any) {
      console.log("🚀 ~ file: SignUp.tsx:37 ~ onFinish ~ error:", error);
      message.error(error?.response?.data?.Error ?? 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <OnboardingCard
        className="card-signup header-solid  ant-card pt-0"
        bordered={false}
      >
        <h5 style={{ textAlign: "center" }}>Forgot password?</h5>
        <p className="text-center my-25 font-semibold text-muted">
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </p>
        <Form
          name="signup"
          initialValues={{ remember: true }}
          className="row-col"
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please input your Email!" },
              { pattern: emailRegex, message: "Please enter valid Email!" },
            ]}
          >
            <Input
              type="email"
              placeholder="Email-Id"
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<ArrowRightOutlined />}
              iconPosition={"end"}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        <p className="font-semibold text-muted text-center">
          Already have an account?
        </p>
        <Button
          block
          type="default"
          onClick={() => {
            navigate("login");
          }}
          disabled={loading}
          icon={<ArrowRightOutlined />}
          iconPosition={"end"}
        >
          Back to Login
        </Button>
      </OnboardingCard>
    </div>
  );
};

export default ForgotPassword;
