import {
  Form,
  Input,
  InputRef,
  Table,
  TableColumnsType,
  TableColumnType,
  Tag,
} from "antd";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useLocation, useNavigate } from "react-router-dom";
import { EmptyData } from "../../../components/common/empty";
import CustomButton from "../../../components/common/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  getUsageMeter,
  getUsageMeters,
} from "../../../redux/feature/billableItems/billableThunk";
import { BillableItemsHeader } from "../Header";
import { StyledTable } from "../../../components/common/table/styles";
import { setFilter } from "../../../redux/feature/customer/customerSlice";
import FilterOption from "../../../components/common/filter/FilterOption";
import { timeAgo } from "../../../utils/helper";
import { StyledTag } from "../../../components/common/tag/styles";

type EventSchemaColumnsType = {
  key: React.Key;
  name: string;
  subTitle?: string;
  aggregation?: string;
  status: "Active" | "Inactive" | "Draft";
  eventSchema: string;
  updatedAt: string;
};

type DataIndex = keyof EventSchemaColumnsType;

const UsageMeters: React.FC<any> = ({ keyProp, currentTabKey }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { filter } = useSelector((state: RootState) => state.customer);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState(searchTerm);
  const { usageMeters, isGetUsageMeter } = useSelector(
    (state: RootState) => state.billable
  );

  const [status, setStatus] = useState("");
  const [formFilter] = Form.useForm();

  useEffect(() => {
    return () => {
      setStatus("");
      dispatch(setFilter("-updated_at"));
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => setSearchValue(searchTerm), 1000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    if (currentTabKey === "1")
      dispatch(
        getUsageMeters({
          sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
          search: searchValue,
          status: status,
        })
      );
  }, [searchValue, filter, status]);

  const usageMetersDataSource = useMemo(() => {
    return usageMeters?.map((item: any) => item);
  }, [usageMeters]);

  const columns: TableColumnsType<any> = [
    {
      title: "Usage Meter Name",
      dataIndex: "name",
      render: (_, { name, subTitle }) => (
        <div>
          <div style={{ fontWeight: "bold" }}>{name}</div>
          {subTitle && (
            <div style={{ fontSize: "12px", color: "#888" }}>{subTitle}</div>
          )}
        </div>
      ),
    },
    {
      title: "Aggregation",
      dataIndex: "aggregation",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => {
        return (
          <StyledTag
            color={
              status.toLocaleLowerCase() === "active"
                ? "green"
                : status.toLocaleLowerCase() === "inactive"
                ? "red"
                : "orange"
            }
          >
            • {status}
          </StyledTag>
        );
      },
    },

    {
      title: "Event Schema",
      dataIndex: "eventSchemaName",
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      render: (text: any) => {
        return timeAgo(text);
      },
    },
  ];

  const onSubmitFilter = async () => {
    try {
      const values = await formFilter.validateFields();
      setStatus(values.status);
      if (values.status) {
        navigate(
          `/usage-meter?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}&` : "&"
          }type=${values.status}`
        );
      } else {
        navigate(
          `/usage-meter?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}` : ""
          }`
        );
      }
    } catch (error) {}
  };

  const handleClearFilter = () => {
    setStatus("");
    formFilter.resetFields();
    navigate(
      `/usage-meter?__sort__=${filter}${
        searchTerm ? `&__search__=${status}` : ""
      }`
    );
  };

  const onSearchHandle = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);

    const baseRoute = "/usage-meter";
    const statusParam = status ? `&status=${status}` : "";
    const searchParam = value ? `&__search__=${value}` : "";
    const finalUrl = `${baseRoute}?__sort__=${filter}${statusParam}${searchParam}`;

    navigate(finalUrl);
  };

  return (
    <div style={{ padding: "0.5rem 0" }}>
      <BillableItemsHeader
        onSearchHandle={onSearchHandle}
        searchTerm={searchTerm}
        onSubmitFilter={onSubmitFilter}
        handleClearFilter={handleClearFilter}
        status={status}
        setStatus={setStatus}
        formFilter={formFilter}
        isUsage={true}
        currentTabKey={currentTabKey}
      />
      {isGetUsageMeter ? (
        <StyledTable
          pagination={false}
          columns={columns}
          loading={true}
          dataSource={[]}
          size="middle"
        />
      ) : usageMeters?.length > 0 ? (
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={usageMetersDataSource}
          size="middle"
          isCursorPointerShow
          onRow={(record: any) => {
            return {
              onClick: () => {
                navigate(`/usage-meter/${record.usageMeterId}`);
              },
            };
          }}
        />
      ) : (
        <EmptyData
          description="No Usage Meters Found"
          btnText="New Usage Meter"
          onClick={() => navigate("/usage-meter/create")}
        />
      )}
    </div>
  );
};

export default UsageMeters;
