import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../api/apiClient";
import { message } from "antd";

interface Credentials {
  email: string;
  password: string;
}

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (_, { rejectWithValue }) => {
    try {
      const { data: user } = await api.get('/profile')
      console.log("⏩ ~ user.data:", user.data)
      const userData = {
        email: user?.data?.email,
        name: user?.data?.name,
        user_id: user?.data?.user_id,
      };
    localStorage.setItem("user", JSON.stringify(userData));
      return user.data;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:24 ~ error:", error)
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  'auth/fetchUsers',
  async ({ type }: { type: 'active' | 'invited' }) => {
    try {
      const response = await api.get(`/users?type=${type}`);
      const users = response.data.data;
      return {users,type}; // Optionally return data for fulfilled action payload
    } catch (err) {
      console.error('Error fetching users:', err);
      throw err; // Ensure rejected action is dispatched in case of an error
    }
  }
);
