import {
  CalendarOutlined,
  ReloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import dayjs from "dayjs";
import { DetailedDiv, StyledRangePicker } from "./styles";
import { EmptyData } from "../../../components/common/empty";
import { useNavigate } from "react-router-dom";
import { StyledTable } from "../../../components/common/table/styles";
import { useMemo, useState } from "react";
import { AnyIfEmpty, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import moment from "moment";
import { getInvoiceById } from "../../../redux/feature/billing/billingThunk";
import { updateInvoices } from "../../../redux/feature/billing/billingSlice";
import { StyledTag } from "../../../components/common/tag/styles";
import {
  capitalizeFirstLetter,
  formatDateToLocalTime,
} from "../../../utils/helper";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const InvoiceTable: React.FC<{
  type: string;
  searchValue?: string;
  filterInvoice?: any;
}> = ({ type, searchValue, filterInvoice }) => {
  const { invoices, loading } = useSelector(
    (state: RootState) => state.billing
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { filter } = useSelector((state: RootState) => state.customer);

  const [spinningRows, setSpinningRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleClick = (rowKey: string) => {
    setSpinningRows((prevState) => ({
      ...prevState,
      [rowKey]: true,
    }));

    setTimeout(() => {
      setSpinningRows((prevState) => ({
        ...prevState,
        [rowKey]: false,
      }));
    }, 2000);
  };

  const invoiceDataSource = useMemo(() => {
    const { customerAccountId, status, startDate, endDate } =
      filterInvoice || {};
    const today = new Date();

    return invoices
      .filter((data) => {
        const invoiceTypeFilter =
          type === "Historic Invoices"
            ? data.status !== "DRAFT" || new Date(data.due_date) < today
            : type === "Ongoing Invoices"
            ? !(new Date(data.due_date) < today)
            : true;

        return (
          invoiceTypeFilter
        );
      })
     
  }, [invoices, searchValue, filter, filterInvoice, type]);
  console.log("►►► ~ invoices:", invoiceDataSource);

  const allColumns = useMemo(
    () => [
      {
        title: "Owner",
        dataIndex: "customer_account_name",
        key: "customer_account_name",
        render: (text: string, record: { customer_account_id: string }) => (
          <DetailedDiv>
            <span className="title">{text}</span>
            <span>{record.customer_account_id}</span>
          </DetailedDiv>
        ),
      },
      {
        title: "Customer",
        dataIndex: "customer_name",
        key: "customer_name",
        render: (text: string, record: { customer_id: string }) => (
          <DetailedDiv>
            <span className="title">{text}</span>
            <span>{record.customer_id}</span>
          </DetailedDiv>
        ),
      },
      {
        title: "Sequence ID",
        dataIndex: "sequence_id",
        key: "sequence_id",
      },
      {
        title: "Billing Cycle",
        dataIndex: "billingCycle",
        key: "billingCycle",
        render: (_: any, record: { start_date: string; end_date: string }) => {
          return (
            <StyledRangePicker
              size="small"
              defaultValue={[dayjs.utc(record.start_date), dayjs.utc(record.end_date)]}
              suffixIcon={<CalendarOutlined />}
              format="MMM DD, YYYY"
              allowClear={false}
              disabled
            />
          );
        },
      },
      ...(type === "Ongoing Invoices"
        ? [
            {
              title: "Generation Date",
              dataIndex: "generationDate",
              key: "generationDate",
              render: (_: any, record: { created_at: string }) => (
                <div>{formatDateToLocalTime(record.created_at)}</div>
              ),
            },
          ]
        : []),
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status: string) => (
          <StyledTag
            color={
              status === "PAID"
                ? "green"
                : status === "DUE"
                ? "purple"
                : status === "PARTIALLY_PAID"
                ? "orange"
                : status === "DRAFT"
                ? "gold"
                : "red"
            }
          >
            {capitalizeFirstLetter(status)}
          </StyledTag>
        ),
      },
      ...(type !== "Ongoing Invoices"
        ? [
            {
              title: "Total Value",
              dataIndex: "total_amount",
              key: "total_amount",
              render: (total_amount: number, record: any) =>
                `${record?.currency_symbol}${total_amount}`,
            },
          ]
        : [
            {
              title: "Invoice Value",
              dataIndex: "invoiceValue",
              key: "invoiceValue",
              render: (_: any, record: any) =>
                `${record?.currency_symbol}${record.total_amount}`,
            },
          ]),

      {
        title: "Paid Value",
        dataIndex: "paid_amount",
        key: "paid_amount",
        render: (paid_amount: number, record: any) =>
          `${record?.currency_symbol} ${paid_amount}`,
      },
      ...(type === "Ongoing Invoices"
        ? [
            {
              dataIndex: "action",
              render: (
                _: any,
                record: {
                  sequence_id: string;
                  status: string;
                  invoice_id: string;
                }
              ) => {
                const { sequence_id, status, invoice_id } = record;
                const isSyncVisible = status !== "DUE" && status !== "PAID";

                return (
                  isSyncVisible && (
                    <SyncOutlined
                      spin={spinningRows[sequence_id] || false}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        handleClick(sequence_id);
                        dispatch(getInvoiceById(invoice_id))
                          .then((res: any) => {
                            const { items, customer, ...rest } = res.payload;
                            dispatch(updateInvoices(rest));
                          })
                          .catch((error: any) => {
                            console.log("►►► ~ dispatch ~ error:", error);
                          });
                      }}
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    />
                  )
                );
              },
            },
          ]
        : []),
    ],
    [type, spinningRows]
  );

  return loading ? (
    <StyledTable
      pagination={false}
      columns={allColumns}
      loading={true}
      dataSource={[]}
      size="middle"
    />
  ) : invoiceDataSource.length === 0 ? (
    <EmptyData description="No Invoices found" />
  ) : (
    <StyledTable
      columns={allColumns}
      dataSource={invoiceDataSource}
      pagination={false}
      // scroll={{ x: "max-content" }}
      isCursorPointerShow
      // loading={loading}
      onRow={(record: any) => {
        return {
          onClick: () => {
            navigate(`/billing/${record.invoice_id}`);
          },
        };
      }}
    />
  );
};

export default InvoiceTable;
