import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
} from "antd";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api/apiClient";
import { useDispatch } from "react-redux";
import { ArrowRightOutlined } from "@ant-design/icons";
import { OnboardingCard } from "./Styled";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location=useLocation()
  const params = new URLSearchParams(location.search);
const token = params.get('token');
const email = params.get('email');


  const onFinish = async (values: any) => {
    setLoading(true);
    try {

      const payload={
        password: values.password,
        resetPasswordToken: token,
        email: email,
      }
      const response = await api.post("verify-password", payload);
      message.success(response?.data?.message);
      navigate('/login')
    } catch (error: any) {
      console.log("🚀 ~ file: SignUp.tsx:37 ~ onFinish ~ error:", error);
      message.error(error?.response?.data?.Error ?? "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <OnboardingCard
        className="card-signup header-solid  ant-card pt-0"
        bordered={false}
      >
        <h5 style={{ textAlign: "center" }}>Reset password</h5>
        <p className="text-center my-25 font-semibold text-muted">
          Enter the new password to login.
        </p>
        <Form
          name="signup"
          initialValues={{ remember: true }}
          className="row-col"
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Please input your Password!" },
              // {
              //   pattern: passwordRegex,
              //   message:
              //     "Password must be at least 8 characters long, with at least one letter, one number, and one special character!",
              // },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<ArrowRightOutlined />}
              iconPosition={"end"}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>

        <Button
          block
          type="link"
          onClick={() => {
            navigate("login");
          }}
          disabled={loading}
          icon={<ArrowRightOutlined style={{rotate:"180deg"}}/>}
              iconPosition={"start"}
        >
          Back to Login
        </Button>
      </OnboardingCard>
    </div>
  );
};

export default ResetPassword;
