import { createSlice } from "@reduxjs/toolkit";
import {
  createBaseCurrency,
  createBillingInfo,
  deleteBillingInfo,
  deleteUserToken,
  generateNewToken,
  getAccountCurrency,
  getAllCountry,
  getAllToken,
  getBillingInfo,
  getCurrencies,
  updateTokenStatus,
  imports,
  getImports,
  importsInitiate,
  archivedBulkImports,
  getBulkImportById,
} from "./adminCenterAsyncThunk";

export interface userToken {
  authToken: any[];
  loading: boolean;
  getTokenLoading: boolean;
}
export interface AdminCenterState {
  currencies: any[];
  loading: boolean;
  userToken: userToken;
  newApiToken: any | undefined;
  alias: any[];
  country: any[];
  AccountCurrency: any[];
  isCurrencyModalOpen: boolean;
  isBulkImportCsvModalOpen: boolean;
  importData: any[];
  billingInfo: {
    bills: any[];
    loading: boolean;
  };
  csvFile: any;
}

const initialState: AdminCenterState = {
  currencies: [],
  loading: false,
  userToken: {
    authToken: [],
    loading: false,
    getTokenLoading: false,
  },
  newApiToken: undefined,
  alias: [],
  country: [],
  AccountCurrency: [],
  isCurrencyModalOpen: false,
  importData: [],
  isBulkImportCsvModalOpen: false,
  billingInfo: {
    bills: [],
    loading: false,
  },
  csvFile: undefined,
};

const adminCenterSlice = createSlice({
  name: "adminCenter",
  initialState,
  reducers: {
    setRemoveToken: (state, action) => {
      state.newApiToken = action.payload;
    },

    addAlias: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.alias = [...state.alias, ...action.payload];
      } else {
        state.alias = [...state.alias, action.payload];
      }
    },
    setCsvFile: (state, action) => {
      state.csvFile = action.payload;
    },
    setAlias: (state, action) => {
      state.alias = action.payload;
    },
    removeAlias: (state, action) => {
      state.alias = state.alias.filter(
        (alias) => alias.alias_id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.currencies = action.payload;
      })
      .addCase(getAccountCurrency.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAccountCurrency.fulfilled, (state, action) => {
        state.loading = false;
        state.AccountCurrency = action?.payload;
        if (!state.AccountCurrency.find((a: any) => a.is_base)) {
          state.isCurrencyModalOpen = true;
        }
      })
      .addCase(getAccountCurrency.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(generateNewToken.pending, (state) => {
        state.userToken.loading = true;
      })
      .addCase(generateNewToken.fulfilled, (state, action: any) => {
        const newToken = {
          ...action.payload,
          is_active: true,
        };

        state.userToken.authToken = [...state.userToken.authToken, newToken];
        state.newApiToken = action.payload;
        state.userToken.loading = false;
      })
      .addCase(generateNewToken.rejected, (state, action) => {
        state.userToken.loading = false;
      })
      .addCase(getAllToken.pending, (state) => {
        state.userToken.getTokenLoading = true;
      })
      .addCase(getAllToken.fulfilled, (state, action: any) => {
        state.userToken.authToken = action.payload;
        state.userToken.getTokenLoading = false;
      })
      .addCase(getAllToken.rejected, (state, action) => {
        state.userToken.getTokenLoading = false;
      })
      .addCase(updateTokenStatus.pending, (state) => {
        state.userToken.getTokenLoading = true;
      })
      .addCase(updateTokenStatus.fulfilled, (state, action: any) => {
        const authToken = state.userToken.authToken;
        const updatedAuthToken = authToken.map((token) =>
          token.token_id === action.payload.tokenId
            ? { ...token, is_active: action.payload.status }
            : token
        );
        state.userToken.authToken = updatedAuthToken;
        state.userToken.getTokenLoading = false;
      })
      .addCase(updateTokenStatus.rejected, (state, action) => {
        state.userToken.getTokenLoading = false;
      })
      .addCase(deleteUserToken.pending, (state) => {
        state.userToken.getTokenLoading = true;
      })
      .addCase(deleteUserToken.fulfilled, (state, action: any) => {
        const tokenIdToDelete = action.payload;
        const updatedAuthToken = state.userToken.authToken.filter(
          (token) => token.token_id !== tokenIdToDelete
        );
        state.userToken.authToken = updatedAuthToken;
        state.userToken.getTokenLoading = false;
      })
      .addCase(deleteUserToken.rejected, (state, action) => {
        state.userToken.getTokenLoading = false;
      })
      .addCase(getAllCountry.fulfilled, (state, action: any) => {
        state.country = action.payload;
      })
      .addCase(createBaseCurrency.pending, (state, action: any) => {
        state.loading = true;
      })
      .addCase(createBaseCurrency.fulfilled, (state, action: any) => {
        state.isCurrencyModalOpen = false;
      })
      .addCase(createBaseCurrency.rejected, (state, action: any) => {
        state.isCurrencyModalOpen = false;
      })
      .addCase(createBillingInfo.pending, (state, action: any) => {
        state.billingInfo.loading = true;
      })
      .addCase(createBillingInfo.fulfilled, (state, action) => {
        if (action.payload.isDefault) {
          state.billingInfo.bills = state.billingInfo.bills.map((info) => ({
            ...info,
            isDefault: false,
          }));
        }
        state.billingInfo.bills = [action.payload, ...state.billingInfo.bills];
        state.billingInfo.loading = false;
      })
      .addCase(createBillingInfo.rejected, (state, action: any) => {
        state.billingInfo.loading = false;
      })
      .addCase(getBillingInfo.pending, (state, action: any) => {
        state.billingInfo.loading = true;
      })
      .addCase(getBillingInfo.fulfilled, (state, action) => {
        state.billingInfo.bills = action.payload.map((item: any) => {
          return { ...item, country: item.address.country };
        });
        state.billingInfo.loading = false;
      })
      .addCase(getBillingInfo.rejected, (state, action: any) => {
        state.billingInfo.loading = false;
      })
      .addCase(deleteBillingInfo.pending, (state, action: any) => {
        state.billingInfo.loading = true;
      })
      .addCase(deleteBillingInfo.fulfilled, (state, action) => {
        const billingIdDelete = action.payload;
        const updatedBillingInfo = state.billingInfo.bills.filter(
          (bill) => bill.billingInformationId !== billingIdDelete
        );
        state.billingInfo.bills = updatedBillingInfo;
        state.billingInfo.loading = false;
      })
      .addCase(deleteBillingInfo.rejected, (state, action: any) => {
        state.billingInfo.loading = false;
      })
      .addCase(imports.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(imports.fulfilled, (state, action) => {
        state.isBulkImportCsvModalOpen = false;
        state.loading = false;
        state.importData = [...state.importData, action.payload];
      })
      .addCase(imports.rejected, (state, action: any) => {
        state.isBulkImportCsvModalOpen = false;
        state.loading = false;
      })
      .addCase(getImports.pending, (state, action: any) => {
        state.loading = true;
      })
      .addCase(getImports.fulfilled, (state, action: any) => {
        state.importData = action.payload;
        state.loading = false;
      })
      .addCase(getImports.rejected, (state, action: any) => {
        state.loading = false;
      })
      .addCase(importsInitiate.pending, (state, action: any) => {
        state.loading = true;
      })
      .addCase(importsInitiate.fulfilled, (state, action: any) => {
        const indexOf = state.importData.findIndex((item: any) => item.importId === action.payload)
        if (indexOf !== -1) {
          state.importData[indexOf] = { ...state.importData[indexOf], status: 'IN_PROGRESS' }
        }
        state.loading = false;
        state.isBulkImportCsvModalOpen = false;
      })
      .addCase(importsInitiate.rejected, (state, action: any) => {
        state.loading = false;
        state.isBulkImportCsvModalOpen = false;

      })
      .addCase(archivedBulkImports.pending, (state, action: any) => {
        state.loading = true;
      })
      .addCase(archivedBulkImports.fulfilled, (state, action: any) => {
        const indexOf = state.importData.findIndex((item: any) => item.importId === action.payload)
        if (indexOf !== -1) {
          state.importData[indexOf] = { ...state.importData[indexOf], status: 'ARCHIVED' }
        }
        state.loading = false;
        state.isBulkImportCsvModalOpen = false;
      })
      .addCase(archivedBulkImports.rejected, (state, action: any) => {
        state.loading = false;
        state.isBulkImportCsvModalOpen = false;

      })
      .addCase(getBulkImportById.pending, (state, action: any) => {
        state.loading = true;
      })
      .addCase(getBulkImportById.fulfilled, (state, action: any) => {
        console.log("►►► ~ .addCase ~ action:", action)
        const indexOf = state.importData.findIndex((item: any) => item.importId === action.payload.importId)
        if (indexOf !== -1) {
          state.importData[indexOf] = action.payload
        }
        state.loading = false;
 
      })
      .addCase(getBulkImportById.rejected, (state, action: any) => {
        state.loading = false;

      })
  },
});

export const { setRemoveToken } = adminCenterSlice.actions;

export const { addAlias, setAlias, removeAlias, setCsvFile } =
  adminCenterSlice.actions;

export const adminCenterReducer = adminCenterSlice.reducer;
